import React from "react";
import "./portfolio.css";

import IMG1 from "../../assets/my_first_portfolio-pic1.jpg";
import IMG2 from "../../assets/E-commerce website.jpg";
import IMG3 from "../../assets/portfolio3.png";
import IMG4 from "../../assets/My second portfoloi- react js.jpg";
import IMG5 from "../../assets/portfolio5.png";
import IMG6 from "../../assets/portfolio6.png";
import IMG7 from "../../assets/constuction Website.png";
import IMG8 from "../../assets/react-table.jpg";
import IMG9 from "../../assets/disharimazi_portfolio1.jpg";
import IMG10 from "../../assets/codehunting1.jpg";

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "First Portfolio Website",
    description: "Skills: HTML, CSS, Javascript",
    github: "https://github.com/aarondb124/Simple-Website-design.git",
    demo: "https://aarondb.xyz/projects/1st_portfolio/",
  },

  {
    id: 2,
    image: IMG2,
    title: "E-commerce Wesite",
    description:
      "Skills: HTML, CSS, Javascript, Bootstarp, Vue js, PHP, MySQL, Laravel",
    github: "https://github.com/aarondb124/My-PHP-Laravel-Projects.git",
    demo: "https://drive.google.com/drive/folders/1oGlErh8-iXJSqKY0uS6yk7bQcLpqDvqV?usp=drive_link",
  },

  {
    id: 3,
    image: IMG4,
    title: "My Second Portfolio Website",
    description: "Skills: HTML, CSS, Javascript, React js, Email Js",
    github: "https://github.com/aarondb124/My-React-Projects.git",
    demo: "http://aarondb.xyz",
  },

  {
    id: 4,
    image: IMG3,
    title: "Movie App",
    description: "Skills: HTML, CSS, Javascript, React js",
    github: "https://https://github.com/aarondb124/My-React-Projects.git.com",
    demo: "http://aarondangshu.xyz/keyboardHero/",
  },

  {
    id: 5,
    image: IMG5,
    title: "Portfolio 2.0",
    description: "Skills: HTML, CSS, Javascript, React js",
    github: "https://github.com",
    demo: "https://aarondb.xyz/projects/Portfolio/",
  },

  {
    id: 6,
    image: IMG6,
    title: "Tic Tac Toe",
    description: "Skills: HTML, CSS, Javascript",
    github: "https://github.com/aarondb124/Short-games.git",
    demo: "https://aarondb.xyz/projects/Tic-Tac-Toe/",
  },

  {
    id: 7,
    image: IMG7,
    title: "Constuction Website",
    description: "Skills: HTML, CSS, Javascript, Boostrap framework",
    github: "https://github.com/aarondb124/Simple-Website-design.git",
    demo: "https://aarondb.xyz/projects/UpConstruction-project/",
  },

  {
    id: 8,
    image: IMG8,
    title: "React Table",
    description: "Skills: HTML, CSS, Javascript, Reactjs, React table",
    github: "https://github.com/aarondb124/My-React-Projects.git",
    demo: "https://aarondb.xyz/projects/react-table/",
  },

  {
    id: 9,
    image: IMG9,
    title: "Wordpress Portfolio",
    description: "Wordpress theme development, Elementor, AES",
    github: "#",
    demo: "https://disharimazi-aarondb-xyz.stackstaging.com/",
  },

  {
    id: 10,
    image: IMG10,
    title: "Code Hunting - NextJS Blog website",
    description: "NextJS, NodeJS, Static Site Generation, API",
    github: "https://github.com/aarondb124/huntingcoder",
    demo: "https://codehunting-five.vercel.app/",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <Swiper
        className="container portfolio_container"
        // install Swiper modules
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={3}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        navigation
        pagination={{ clickable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-offset="300"
      >
        {data.map(({ id, image, title, description, github, demo }) => {
          return (
            <SwiperSlide key={id} className="portfolio_item">
              <div className="portfolio_item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <h5>{description}</h5>
              <div className="porfolio_item-cta">
                <a
                  href={github}
                  className="btn"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github
                </a>
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Portfolio;
