import React from 'react';
import { BsLinkedin } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { SiLinktree } from "react-icons/si";
import { FaRedditAlien } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";


const HeaderSocials = () => {
  return (
    <div className='header_socials'>
      <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/aaron-d-angshu-3b2228193/" >< BsLinkedin /></a>
      <a target="_blank" rel="noreferrer" href="https://github.com/aarondb124" >< BsGithub /></a>
      <a target="_blank" rel="noreferrer" href="https://www.facebook.com/aarondb124page/" ><BsFacebook/></a>
      <a target="_blank" rel="noreferrer" href="https://linktr.ee/aarondb124" ><SiLinktree/></a>
      <a target="_blank" rel="noreferrer" href="https://www.reddit.com/user/aarondb124/" ><FaRedditAlien/></a>
      <a target="_blank" rel="noreferrer" href="https://twitter.com/aarondb124" ><BsTwitterX/></a>
    </div>
  )
}

export default HeaderSocials