import React from 'react';
import './services.css'
import { BsCheckLg } from 'react-icons/bs'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I offer</h5>
      <h2>&lt;Services&#47;&gt;</h2>

      <div className="container services_container">
      
      {/*UI/UX Design*/}

      <article className="service" 
      data-aos="zoom-out-right"
      data-aos-duration="1000"
      data-aos-offset="300"
      
      >
          <div className="service_head">
            <h3>Data Entry Jobs</h3>
          </div>

          <ul className='service_list'>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>professional CV design.</p>
            </li>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Letter writting both English and Bangla.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Creating visiting Cards.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Creating Excel user interctive charts.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Excel data entry.</p>
            </li>
            

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Power point Project creation.</p>
            </li>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Converting PDF into Excel or Word documant.</p>
            </li>
            
          </ul>
        </article>

         {/*Web Development*/}

        <article className="service"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-offset="300"
        
        >
          <div className="service_head">
            <h3>Web Development</h3>
          </div>

          <ul className='service_list'>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Disigning a fully functional website.</p>
            </li>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Developing a user interctive dynamic website.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Designing a Normal portfolio website.</p>
            </li>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Designing a Professional portfolio website.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Designing dynamic ECommerce website.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Designing Blog website.</p>
            </li>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Profitable or Non-profitable any website</p>
            </li>
            
          </ul>
        </article>

        {/*Content Creation*/}

        <article className="service"
        data-aos="zoom-out-left"
        data-aos-duration="1000"
        data-aos-offset="300"
        
        >
          <div className="service_head">
            <h3>Digital Marketing</h3>
          </div>

          <ul className='service_list'>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Search engine optimization.</p>
            </li>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Content marketing</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Social media marketing.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Pay-per-click marketing.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Marketing automation.</p>
            </li>

            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Email marketing.</p>
            </li>
            
            <li>
              < BsCheckLg className='service_list-icon'/>
              <p>Youtube channel Monitaization.</p>
            </li>

          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services